import React from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'antd'
import moment from 'moment'
import PT from 'prop-types'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bold {
    font-weight: bold;
  }
`

const ModalContent = styled.div`
  max-height: 70vh;
  overflow: scroll;
  .bold {
    font-weight: bold;
  }
  .primary {
    color:#2677B4;
  }
`

const Title = styled.span`
  color: #2677B4;
  font-size: 14px;
  line-height: 24px;
`

const FooterContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`
const ActivityCard = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false)
  if (data.service === 'Comment' || data.service === 'Booking') {
    return <div></div>
  }
  return (<>
    <Container>
      <div>
        <Title>
          <span className='bold'>{data?.message}</span>
           ({data?.service})
        </Title>
        <div>
          {moment(data?.creation).format('DD MMMM YYYY HH:mm')}
        </div>
      </div>
      <div>
        <Button onClick={() => setShowModal(true)}>ดูรายละเอียด</Button>
      </div>
    </Container>
    <Modal
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={<FooterContainer>
        <Button onClick={() => setShowModal(false)} danger>ลบ</Button>
        <Button onClick={() => setShowModal(false)} type='primary'>ปิด</Button>
      </FooterContainer>}
    >
      <ModalContent>
        <div>
          <Title>
            <span className='bold'>{data?.message}</span>
           ({data?.service})
          </Title>
          <div>
            {moment(data?.creation).format('DD MMMM YYYY HH:mm')}
          </div>
          <br />
        </div>
        <div>
          <ol>
            {
              data?.body.data.filter(e => e.field_id).map((item, index) => {
                return (<li key={index}>
                  <div>{item?.field_name}</div>
                  <div className='primary'>{item?.field_value}</div>
                </li>)
              })
            }
          </ol>
        </div>
      </ModalContent>
    </Modal>
  </>)
}

ActivityCard.propTypes = {
  data: PT.shape({
    message: PT.string,
    service: PT.string,
    creation: PT.number,
    body: PT.shape({
      data: PT.array
    })
  })
}
export default ActivityCard
