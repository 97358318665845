import React, { useState, useEffect } from 'react'
import PT from 'prop-types'
import { Modal, Select, Row, Col, Checkbox, Button, Input } from 'antd'
import styled from 'styled-components'

import ChatItem from './ChatItem'
import InputField from '../../components/InputField'

const ContainerSentence = styled.div`
  max-height: 240px;
  overflow: scroll;
`

const Sentence = styled.div`
  padding: 10px 0;
`

const ModalKeyword = ({
  keywordList,
  previewMessage,
  platform,
  showModalKeyword,
  handleSelectedKeyword,
  confirmModal,
  closeModal,
  selectedKeyword,
  userLastMessage
}) => {
  const field = {
    LineBot: 'line_messages',
    LineModular: 'line_messages',
    FacebookBot: 'facebook_messages'
  }

  const [learning, setLearning] = useState(false)
  const [sentences, setSentences] = useState([])

  const submitModal = () => {
    confirmModal(learning)
    closeModal()
  }

  useEffect(() => {
    const temp = keywordList.data.find(keyword => keyword.id === selectedKeyword)
    if (temp) {
      setSentences(temp.sentences)
    }
  }, [selectedKeyword])

  return (
    <Modal
      visible={showModalKeyword}
      style={{ top: 20 }}
      footer={null}
      onCancel={closeModal}
    >
      <Row gutter={16}>
        <Col span={12}>
          <div style={{
            minHeight: '500px',
            margin: '-24px 0 -24px -24px',
            padding: '8px',
            background: '#D2DAE8'
          }}>
            {platform && previewMessage[field[platform]].map((chat, index) => {
              return (
                <div key={index}>
                  <ChatItem
                    key={chat.id}
                    data={{
                      ...chat,
                      source: {
                        type: 'PREVIEW'
                      }
                    }}
                    platform={platform}

                  />
                </div>
              )
            })}
          </div>
        </Col>
        <Col span={12}>
          <InputField title="เลือกคีย์เวิร์ด">
            <Select
              showSearch
              optionFilterProp="label"
              size="small"
              style={{ width: '100%' }}
              onChange={val => handleSelectedKeyword(val)}
              value={selectedKeyword}
            >
              {(keywordList.data || []).map(t => (
                <Select.Option label={t.title} key={t.id}>{t.title}</Select.Option>
              ))}
            </Select>
          </InputField>
          <Checkbox onChange={(e) => setLearning(e.target.checked)}>สอนคีย์เวิร์ด</Checkbox>
          <div>
            <InputField title="คีย์เวิร์ด">
              <Input
                disabled={!learning}
                readOnly
                value={userLastMessage}
              />
            </InputField>
          </div>
          <ContainerSentence>
            {
              sentences.map((item, index) => <Sentence key={index}>{item.text}</Sentence>)
            }
          </ContainerSentence>
          <div style={{ position: 'absolute', bottom: 0, right: 0, width: '100%', textAlign: 'right' }}>
            <Button onClick={() => submitModal()} type='primary' style={{ marginRight: '10px' }}>ส่ง</Button>
            <Button onClick={closeModal}>ยกเลิก</Button>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

ModalKeyword.propTypes = {
  data: PT.array,
  keywordList: PT.array,
  previewMessage: PT.object,
  platform: PT.string,
  showModalKeyword: PT.array,
  closeModal: PT.func,
  handleSelectedKeyword: PT.func,
  confirmModal: PT.func,
  selectedKeyword: PT.string,
  userLastMessage: PT.string
}

export default ModalKeyword
