import React from 'react'
import PT from 'prop-types'
import { truncate } from '../../../helpers/truncateString'

const FileMessage = ({ text, style, bubbleColor, url }) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        padding: '4px 12px',
        color: bubbleColor.text,
        backgroundColor: bubbleColor.background,
        borderRadius: '16px',
        ...style
      }}
    >
      <a style={{ color: bubbleColor.text, textDecoration: 'underline' }} target="_blank" href={url} rel="noreferrer">
        {truncate(text)}
      </a>
    </div>
  )
}

FileMessage.propTypes = {
  text: PT.string,
  url: PT.string,
  bubbleColor: PT.shape({
    text: PT.string,
    background: PT.string
  }),
  style: PT.object
}

FileMessage.defaultProps = {
  text: '',
  bubbleColor: {
    text: 'rgba(0, 0, 0, 0.65)',
    background: '#dddddd'
  },
  style: {}
}

export default FileMessage
