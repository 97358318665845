import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

import ImageRenderer from '../../components/ImageRenderer'

const Container = styled.div`
  position: relative;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${({ color }) => `
    background-color: ${color + '40'};
    color: ${color};
    `}
  }
  ${({ selected, color }) =>
    selected &&
    `
    background-color: ${color}40;
    color: ${color};
    font-weight: 600;
    &:after {
      display: block;
      content: "";
      position: absolute;
      right: 0px;
      left: 0px;
      bottom: 0px;
      height: 5px;
      background-color: ${color};
      transition: all 0.35s ease 0s;
    }
  `}
`

const LightenColor = (color, percent) => {
  var num = parseInt(color.replace('#', ''), 16)
  var amt = Math.round(2.55 * percent)
  var R = (num >> 16) + amt
  var B = (num >> 8 & 0x00FF) + amt
  var G = (num & 0x0000FF) + amt
  return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)
}

const PlatformItem = ({ data, onClick, selected, icon, color, channelList, onClickChannel, selectedChannelId }) => {
  const [isHover, setHover] = React.useState(false)
  return (<>
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      selected={selected}
      color={color}
    >
      <ImageRenderer
        style={{ width: 18, height: 18, marginBottom: 8, marginRight: '12px' }}
        src={selected || isHover ? icon.active : icon.deactive}
        alt={data.key}
      />
      <span>{data.title}</span>
    </Container>
    {
      selected && channelList.map(channel => {
        return (<Container
          key={channel.id}
          selected={channel.id === selectedChannelId}
          color={LightenColor(color, 20)}
          onClick={() => onClickChannel({ channel_id: channel.id })}>
          {channel.title}
        </Container>)
      })
    }
  </>
  )
}

PlatformItem.propTypes = {
  data: PT.shape({
    key: PT.oneOf(['All', 'FacebookBot', 'LineBot', 'LineModular']),
    title: PT.string
  }),
  selected: PT.bool,
  onClick: PT.func
}

PlatformItem.defaultProps = {
  onClick: () => {}
}

export default PlatformItem
