import React from 'react'
import PT from 'prop-types'
import { SettingFilled, FormOutlined } from '@ant-design/icons'
import { Tag, Menu, Dropdown, Modal, Form, Input, Button, Divider, Tabs } from 'antd'

import AIYA_BLANK from '../../assets/images/AIYA_BLANK.png'
import ImageRenderer from '../../components/ImageRenderer'
import InputField from '../../components/InputField'
import TabComment from './TabComment'
import TabUserInfo from './TabUserInfo'
import TabOther from './TabOther'

const IMG_SIZE = 150

const { TabPane } = Tabs

const ChatInfo = ({ inbox, onUpdateProfile, contact, botTags }) => {
  const formUserInfoRef = React.useRef(null)
  const [showModal, setShowModal] = React.useState(false)
  const [showUserInfoModal, setShowUserInfoModal] = React.useState(false)

  const connectWisible = () => {
    setShowModal(false)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowModal(true)}>
        ตั้งค่า Wisible
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div style={{ padding: '24px' }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div
            style={{
              position: 'absolute',
              top: '15px',
              right: '10px',
              cursor: 'pointer'
            }}
          >
            <SettingFilled />
          </div>
        </Dropdown>
        <div style={{ display: 'flex' }}>
          <ImageRenderer
            src={inbox.picture_url}
            errorImg={AIYA_BLANK}
            alt="info-profile"
            style={{ borderRadius: '50%', width: IMG_SIZE, height: IMG_SIZE, margin: '30px auto 16px' }}
          />
        </div>
        <div style={{ textAlign: 'center', marginBottom: 8 }}>
          <span style={{ fontWeight: 600, fontSize: '1.2em' }}>{inbox.title}</span>
        </div>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 16 }}
        >
          {(inbox.tags || []).map(t => (
            <Tag key={t} style={{ margin: 4 }}>
              {t}
            </Tag>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setShowUserInfoModal(true)}><FormOutlined />แก้ไขข้อมูลส่วนตัว</Button>
        </div>
        <Divider />
        <Tabs defaultActiveKey="1" >
          <TabPane tab="บันทึกเพิ่มเติม" key="1">
            <TabComment
              userId={inbox.id}
            />
          </TabPane>
          <TabPane tab="อื่นๆ" key="2">
            <TabOther
              userId={inbox.id}
            />
          </TabPane>
        </Tabs>
      </div>
      <Modal
        title="เชื่อมต่อกับ Wisible"
        visible={showModal}
        onOk={connectWisible}
        onCancel={closeModal}
        style={{ top: 20 }}
        footer={[
          <Button key="back" onClick={() => closeModal()}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={() => connectWisible()}>
            เชื่อมต่อ
          </Button>
        ]}
      >
        <Form>
          <InputField title="Contact">
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>
          </InputField>
          <InputField title="Company">
            <Form.Item name="company">
              <Input />
            </Form.Item>
          </InputField>
          <InputField title="Phone">
            <Form.Item name="phone">
              <Input />
            </Form.Item>
          </InputField>
          <InputField title="Value">
            <Form.Item name="value">
              <Input />
            </Form.Item>
          </InputField>
          <InputField title="Labels">
            <Form.Item name="labels">
              <Input />
            </Form.Item>
          </InputField>
        </Form>
      </Modal>
      <Modal
        title="แก้ไขข้อมูลส่วนตัว"
        visible={showUserInfoModal}
        style={{ top: 0 }}
        onCancel={() => {
          setShowUserInfoModal(false)
        }}
        destroyOnClose
        footer={[
          <Button key="submit" type="primary" onClick={() => {
            formUserInfoRef.current.submit()
            setShowUserInfoModal(false)
          }}>
            บันทึก
          </Button>,
          <Button key="back" onClick={() => {
            setShowUserInfoModal(false)
          }}>
            ยกเลิก
          </Button>
        ]}
      >
        <TabUserInfo
          botTags={botTags}
          refForm={formUserInfoRef}
          data={contact}
          hideButton
          onUpdateProfile={onUpdateProfile}
        />
      </Modal>
    </>
  )
}

ChatInfo.propTypes = {
  inbox: PT.shape({
    id: PT.string,
    title: PT.string,
    picture_url: PT.string,
    status: PT.string,
    tags: PT.arrayOf(PT.string)
  }),
  contact: PT.any
}

export default React.memo(ChatInfo)
