import React from 'react'
import PT from 'prop-types'
import FileImageOutlined from '@ant-design/icons'

import ImageRenderer from '../../../components/ImageRenderer'

const IMAGE_SIZE = 240

const TemplateCardMessage = ({ data, ratio }) => {
  const getImageHeight = () => {
    if (ratio === 'fb_horizon') {
      return IMAGE_SIZE / 1.91
    } else if (ratio === 'line_horizon') {
      return IMAGE_SIZE / 1.51
    } else {
      return IMAGE_SIZE
    }
  }

  return (
    <div style={{ overflow: 'scroll', whiteSpace: 'nowrap', width: '100%' }}>
      {data.map(card => (
        <div
          key={card.key}
          style={{
            width: IMAGE_SIZE,
            display: 'inline-block',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: 8,
            overflow: 'hidden',
            marginRight: 8,
          }}
        >
          {card.imageUrl && (
            <div style={{ width: IMAGE_SIZE, height: getImageHeight() }}>
              <ImageRenderer
                src={card.imageUrl}
                errorComponent={
                  <div
                    style={{
                      textAlign: 'center',
                      padding: 12,
                      backgroundColor: '#eaeaea',
                      color: '#7e7e7e',
                      borderRadius: 8,
                    }}
                  >
                    <FileImageOutlined style={{ fontSize: '2.2em' }} />
                    <div>
                      <small>Image has been expired.</small>
                    </div>
                  </div>
                }
                alt="title"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          )}
          <div style={{ padding: 8 }}>
            {card.title && (
              <div
                style={{ fontWeight: 600, fontSize: '1.2em', maxWidth: 'calc(100% - 16px)' }}
                className="text-wrap-ellipsis"
              >
                {card.title}
              </div>
            )}
            {card.description && <div style={{ whiteSpace: 'pre-wrap' }}>{card.description}</div>}
          </div>
          <div>
            {(card.buttons || []).map((b, i) => (
              <div
                key={i}
                style={{
                  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  padding: 8,
                }}
              >
                {b}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

TemplateCardMessage.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      title: PT.string,
      description: PT.string,
      imageUrl: PT.string,
      buttons: PT.arrayOf(PT.string),
    }),
  ),
}

TemplateCardMessage.defaultProps = {
  data: [],
}

export default TemplateCardMessage
