import React from 'react'
import PT from 'prop-types'

import ActivityCard from './ActivityCard'
import useActivityData from '../../../hooks/useActivityData'

const TabOther = ({
  userId
}) => {
  const { activityList } = useActivityData(userId, 'Typeform')

  // React.useEffect(() => {
  //   if (userId) {
  //     searchActivity({
  //       variables: { filters: { services: ['Typeform'], user_id: userId } }
  //     })
  //   }
  // }, [activityOtherList.data[0], userId])

  return (<div>
    {
      activityList?.data?.length > 0 ? (<div style={{ width: '100%' }}>
        {
          activityList.data.map((item, index) => {
            return (<ActivityCard key={index} data={item} />)
          })
        }
      </div>) : ''
    }
  </div>
  )
}

TabOther.propTypes = {
  userId: PT.string
}
export default TabOther
