import React from 'react'
import PT from 'prop-types'
import { Input, Form, Button, Row, Col, Select } from 'antd'

import InputField from '../../components/InputField'
import TextArea from 'antd/lib/input/TextArea'
const TabUserInfo = ({ onUpdateProfile, data, hideButton = false, refForm, botTags }) => {
  const [form] = Form.useForm()
  const { contact, tags = [], about } = data

  const onSubmitForm = (value) => {
    const { tags, about, ...rest } = value
    onUpdateProfile({ contact: rest, tags, about })
  }

  const initialValue = () => {
    form.setFieldsValue({
      first_name: contact?.first_name,
      last_name: contact?.last_name,
      tel: contact?.tel,
      email: contact?.email,
      nickname: contact?.nickname,
      company: contact?.company,
      tags: tags,
      position: contact?.position,
      about: about
    })
  }
  React.useEffect(() => {
    initialValue()
  }, [contact, tags])

  return (<div>
    <Form
      ref={refForm}
      onFinish={onSubmitForm}
      form={form}
    >
      <Row gutter={16}>
        <Col span={12}>
          <InputField title="ชื่อ">
            <Form.Item
              name="first_name"
            >
              <Input/>
            </Form.Item>
          </InputField>
        </Col>
        <Col span={12}>
          <InputField title="นามสกุล">
            <Form.Item name="last_name">
              <Input />
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <InputField title="แท๊ก">
            <Form.Item
              name="tags"
            >
              <Select
                mode="tags"
                size="small"
                style={{ width: '100%' }}
              >
                {(botTags || []).map(t => (
                  <Select.Option key={t.title}>{t.title}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <InputField title="ชื่อเล่น">
            <Form.Item
              name="nickname"
            >
              <Input />
            </Form.Item>
          </InputField>
        </Col>
        <Col span={12}>
          <InputField title="เบอร์โทรติดต่อ">
            <Form.Item name="tel">
              <Input />
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <InputField title="อีเมล">
            <Form.Item
              name="email"
            >
              <Input />
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <InputField title="ชื่อบริษัท/หน่วยงาน/ร้านค้า">
            <Form.Item
              name="company"
            >
              <Input />
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <InputField title="ตำแหน่ง">
            <Form.Item
              name="position"
            >
              <Input />
            </Form.Item>
          </InputField>
        </Col>
      </Row> <Row gutter={16}>
        <Col span={24}>
          <InputField title="เพิ่มบันทึก">
            <Form.Item
              name="about"
            >
              <TextArea />
            </Form.Item>
          </InputField>
        </Col>
      </Row>
      {
        !hideButton && (
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button htmlType="submit" type="primary">บันทึกการเปลี่ยนแปลง</Button>
            </Col>
          </Row>
        )
      }
    </Form>
  </div>
  )
}

TabUserInfo.propTypes = {
  onUpdateProfile: PT.func,
  data: PT.object,
  hideButton: PT.bool,
  refForm: PT.func,
  botTags: PT.array
}
export default React.memo(TabUserInfo)
