import React from 'react'
import PT from 'prop-types'
import moment from 'moment'

const DateSnack = ({ timestamp }) => {
  const obj = timestamp ? moment(Number(timestamp)) : moment()
  const str = obj.format('ddd, MMM D')
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 16,
      }}
    >
      <span
        style={{
          fontSize: '0.8em',
          fontWeight: 600,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          padding: '3px 8px',
          borderRadius: 16,
        }}
      >
        {str}
      </span>
    </div>
  )
}

DateSnack.propTypes = {
  timestamp: PT.number,
}

export default DateSnack
