import React from 'react'
import PT from 'prop-types'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import BOT_BLANK from '../../assets/images/AIYA_BLANK.png'
import ImageRenderer from '../../components/ImageRenderer'
import colors from '../../constants/color'
import MessageRender from './MessageRender'

const IMAGE_SIZE = 32

const ChatItem = ({ platform, data, chat_picture, onImageLoaded }) => {
  const { botId } = useParams()
  const platformColor =
    ["LineBot", "LineModular"].includes(platform)
      ? { text: 'rgba(0, 0, 0, 0.65)', background: colors.LINE_BUBBLE }
      : { text: 'white', background: colors.FB_BUBBLE }
  const source = data.source || {}
  const postback = data.postback || {}
  let message = data.message || {}

  if (postback.data) {
    message = {
      type: 'text',
      text: postback.data
    }
  } else if (postback.payload) {
    message = {
      type: 'text',
      text: postback.payload
    }
  }
  if (source.type === 'PREVIEW') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: 8,
          marginBottom: 8
        }}
      >
        <div style={{ maxWidth: '95%' }}>
          <MessageRender
            message={message}
            bubbleColor={platformColor}
            botId={botId}
            channelId={data.channel_id}
            onImageLoaded={onImageLoaded}
            platform={platform}
          />
        </div>
      </div>
    )
  }

  if (source.type === 'USER') {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 8, marginBottom: 8 }}>
        <ImageRenderer
          src={chat_picture}
          errorImg={BOT_BLANK}
          alt="user"
          style={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            borderRadius: '50%',
            marginRight: 8,
            backgroundColor: '#cccccc'
          }}
        />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <MessageRender
            message={message}
            botId={botId}
            channelId={data.channel_id}
            onImageLoaded={onImageLoaded}
            platform={platform}
          />
          <small style={{ color: '#b7b7b7', marginLeft: 8 }}>
            {moment(data.timestamp).format('HH:mm')}
          </small>
        </div>
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: 8,
          marginBottom: 8,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'flex-end',
            color: '#b7b7b7',
            marginRight: 8
          }}
        >
          {source.type === 'ADMIN' && <small>sent by {source.name || 'admin'}</small>}
          <small>{moment(data.timestamp).format('HH:mm')}</small>
        </div>
        {/* <div style={{ maxWidth: '50%' }}> */}
        <div>
          <MessageRender
            message={message}
            bubbleColor={platformColor}
            botId={botId}
            channelId={data.channel_id}
            onImageLoaded={onImageLoaded}
            platform={platform}
          />
        </div>
      </div>
    )
  }
}

ChatItem.propTypes = {
  chat_picture: PT.string,
  platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineModular']),
  data: PT.shape({
    id: PT.string,
    chat_id: PT.string,
    bot_id: PT.string,
    channel_id: PT.string,
    timestamp: PT.number,
    source: PT.shape({
      type: PT.oneOf(['USER', 'BOT', 'ADMIN', 'PREVIEW']),
      id: PT.string
    }),
    message: PT.object,
    postback: PT.object
  }),
  onImageLoaded: PT.func
}

ChatItem.defaultProps = {
  onImageLoaded: () => {}
}

export default ChatItem
