import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { client } from './api/apollo'
import AppContainer from './container/AppContainer'
import HeightController from './libs/HeightController'
import Portal from './Portal'
// http://localhost:3001/b/p?token=eyJ1IjpudWxsLCJiIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjBlWEJsSWpvaVltOTBJaXdpYVdRaU9pSTNNRE14TlRZMllpSXNJbk4xWWlJNklqY3dNekUxTmpaaUlpd2lkWE5sY2w5cFpDSTZJakk0T0RZMU5UY3hOelF5TnpNek1URWlMQ0ptYVhKemRGOXVZVzFsSWpvaUlpd2liR0Z6ZEY5dVlXMWxJam9pSWl3aVptbHNkR1Z5SWpwN2ZTd2lhV0YwSWpveE5qQXpOekkyTmpReExDSmxlSEFpT2pFMk1ETTRNVE13TkRGOS5BejdsNnZua3QwZ2JVbWZzWWhjTXVKZ2NyTGJJV3JlNDNTUWRqbjdKUmprIiwiaWQiOiI3MDMxNTY2YiIsImRlc3QiOiIifQ==

// chanintr
// http://localhost:3002/b/p?token=eyJ1IjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjBlWEJsSWpvaWRYTmxjaUlzSW1sa0lqb2lWVEUxTlRnek9XRm1aVEppWW1abVlqZzJZV1F6TmpFelkyRTNaVEV5TVdJeUlpd2ljSEp2ZG1sa1pYSWlPaUpzYVc1bElpd2ljM1ZpSWpvaVZURTFOVGd6T1dGbVpUSmlZbVptWWpnMllXUXpOakV6WTJFM1pURXlNV0l5SWl3aWFXRjBJam94TlRrME56SXdOak0xTENKbGVIQWlPakUxT1RRNE1EY3dNelY5Lkg1ZEdORklVUFhleFpJakJ5bm9UQ2VqY0JMaFJXNVFFdUpvNnZfOVdLdVkiLCJiIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjBlWEJsSWpvaVltOTBJaXdpYVdRaU9pSXpZMk5rWkRsaFlTSXNJbk4xWWlJNklqTmpZMlJrT1dGaElpd2lkWE5sY2w5cFpDSTZJbFV4TlRVNE16bGhabVV5WW1KbVptSTRObUZrTXpZeE0yTmhOMlV4TWpGaU1pSXNJbVpwY25OMFgyNWhiV1VpT2lKQmRHTm9ZWEpwZVdFaUxDSnNZWE4wWDI1aGJXVWlPaUpFWVhKdmRHVWlMQ0ptYVd4MFpYSWlPbnQ5TENKcFlYUWlPakUxT1RRM016UXhOVFFzSW1WNGNDSTZNVFU1TkRneU1EVTFOSDAuQThHMTVEdl95Skc1dHp4U3cza2pYQmttSkhhQ2pMY1NOT2k1cnNhZHo4VSIsImlkIjoiM2NjZGQ5YWEifQ==
const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <HeightController>
          <div className="full-height">
            <Switch>
              <Route path="/" render={() => <h1>Home</h1>} exact />
              <Route path="/:botId/inbox" component={AppContainer} />
              <Route path="/p" component={Portal} />
            </Switch>
          </div>
        </HeightController>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
