import { useState } from 'react'
import isEqual from 'lodash.isequal'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { ErrorType } from '../helpers/errorType'

const SEARCH_INBOX = gql`
  query searchInbox($filters: InboxSearchFilter, $page: InboxSearchPagination) {
    searchInbox(input: {
      filters: $filters, page: $page
    }) {
      total
      next
      data {
        id
        chat_id
        title
        picture_url
        read
        tags
        platform
        status
        event
      }
    }
  }
`

const ON_INBOX_CHANGED = gql`
  subscription onInboxChanged($filters: InboxSubscriptionFilter) {
    onInboxChanged(filters: $filters) {
      operation
      data {
        id
        chat_id
        bot_id
        title
        type
        picture_url
        tags
        creation
        platform
        status
        event
        read
        bot_disabled
      }
    }
  }
`

const useInboxData = (botId, opts = {}) => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState({ size: opts.size, start: opts.start })
  const [filters, setFilters] = useState({ status: 'ALL' })
  const [store, setStore] = useState({
    total: 0,
    next: '',
    data: []
  })
  const [tunnel, setTunnel] = useState('')

  const loadmore = () => {
    if (store.next == null || loading) {
      return
    }
    setTunnel('loadmore')
    setPage({ size: opts.size, start: store.next })
    setLoading(true)
  }

  const handleSetFilters = items => {
    if (items.platform || items.platform === 'All') {
      delete filters.channel_id
    }

    if (!isEqual(filters, { ...filters, ...items })) {
      setFilters({ ...filters, ...items })
      setLoading(true)
    }
  }

  useQuery(SEARCH_INBOX, {
    fetchPolicy: 'no-cache',
    variables: { page, filters },
    onError: err => {
      const errorType = err?.graphQLErrors[0]?.message
      if (errorType === ErrorType.SCROLLING_EXPIRED) {
        setTunnel('')
        setStore({ total: 0, next: '', data: [] })
        setPage({ size: opts.size, start: '' })
        setLoading(true)
      } else {
        console.error(err)
      }
    },
    onCompleted: ({ searchInbox }) => {
      if (tunnel === 'loadmore') {
        setStore({ ...searchInbox, data: [...store.data, ...searchInbox.data] })
        setTunnel('')
      } else {
        setStore(searchInbox)
        setPage({ size: opts.size, start: opts.start })
      }
      setLoading(false)
    }
  })

  useSubscription(ON_INBOX_CHANGED, {
    variables: { filters: { status: filters.status, tags: filters.tags, platform: filters.platform, channel_id: filters.channel_id } },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onInboxChanged = {} }
      } = subscriptionData
      const { data: newInbox, operation } = onInboxChanged
      const tmp = { ...newInbox }
      delete tmp.bot_id
      delete tmp.doctype
      delete tmp.op
      let newInboxes = [...store.data]
      const isExist = !!newInboxes.find(ib => ib.id === (newInbox.chat_id || newInbox.id))
      if (isExist) {
        newInboxes = newInboxes.map(ib =>
          ib.id === (newInbox.chat_id || newInbox.id) ? { ...ib, ...tmp } : ib
        )
      } else {
        newInboxes = [...newInboxes, tmp]
      }
      setStore({ ...store, data: newInboxes })
    }
  })

  return {
    data: (store.data || []).sort(
      (a, b) => (b.event || {}).timestamp - (a.event || {}).timestamp
    ),
    loading,
    loadmore,
    filters,
    setFilters: handleSetFilters
  }
}

export default useInboxData
