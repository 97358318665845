import React from 'react'
import VideoPlayer from '../../../libs/VideoPlayer'

const VideoMessage = (props) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        padding: '4px 12px',
        borderRadius: '16px',
        // ...style,
      }}
    >
      <VideoPlayer {...props} className="video-js vjs-default-skin vjs-big-play-centered" width={220}/>
    </div>
  )
}

export default VideoMessage
