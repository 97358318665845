import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { Tag, Menu, Dropdown } from 'antd'
import { EllipsisOutlined, EyeInvisibleOutlined, FormOutlined } from '@ant-design/icons'

import color from '../../constants/color'
import ImageRenderer from '../../components/ImageRenderer'

const Container = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #edf0ff;
  }
  ${({ selected }) => selected && 'background-color: #edf0ff'}
`

const IMAGE_SIZE = 60

const InboxItem = ({ data, onClick, selected, onUpdateInbox, onMarkAsUnread }) => {
  const unread = !data.read
  const platformColor = ["LineBot", "LineModular"].includes(data.platform) ? color.LINE_THEME : color.FB_THEME

  const getChatDate = date => {
    const str = date.calendar(null, {
      sameDay: 'HH:mm',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'DD/MM/YY'
    })
    return str
  }

  const getEventMessage = event => {
    if (event.type === 'message') {
      const eventMessage = event.message
      if (eventMessage.type === 'text') {
        return eventMessage.text
      } else if (eventMessage.type === 'image') {
        return 'Sent an image.'
      } else if (eventMessage.type === 'file') {
        return 'Sent a file.'
      } else if (eventMessage.type === 'sticker') {
        return 'ส่งสติ๊กเกอร์'
      }
    } else if (event.type === 'postback') {
      return (event.postback || {}).data || '-'
    } else if (event.type === 'follow') {
      return 'เพิ่มเพื่อน'
    }
    return '-'
  }

  const handleMenuClick = e => {
    const key = e.key
    const isCmd = key.startsWith('cmd:')
    if (!isCmd) {
      onUpdateInbox({ status: key })
    } else {
      const cmd = key.replace('cmd:', '')
      if (cmd === 'mark-as-unread') {
        onMarkAsUnread()
      }
    }
  }

  return (
    <Container selected={selected}>
      <div
        style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 24px - 8px)' }}
        onClick={onClick}
      >
        <div
          style={{ position: 'relative', width: IMAGE_SIZE, height: IMAGE_SIZE, marginRight: 16 }}
        >
          <ImageRenderer
            src={data.picture_url}
            alt={data.title}
            style={{
              backgroundColor: '#cccccc',
              borderRadius: '50%',
              width: '100%',
              height: '100%',
              border: `3px solid ${platformColor}`,
              minWidth: '60px',
              minHeight: '60px',
              maxWidth: '60px',
              maxHeight: '60px',
              overflow: 'hidden'
            }}
          />
          {unread && (
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'red',
                border: '2px solid white',
                position: 'absolute',
                bottom: 2,
                right: 2
              }}
            />
          )}
        </div>
        <div style={{ width: 'calc(100%  - 60px - 16px)' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <span
              className="text-wrap-ellipsis"
              style={{
                maxWidth: 'calc(100% - 90px)',
                fontSize: '1.1em',
                fontWeight: unread ? 700 : 500,
                marginRight: 4
              }}
            >
              {data.title}
            </span>
            {data.status === 'FOLLOWED_UP' && (
              <Tag style={{ margin: 0 }} color="gold">
                ต้องดำเนินการ
              </Tag>
            )}
            {data.status === 'DONE' && (
              <Tag style={{ margin: 0 }} color="green">
                ดำเนินการแล้ว
              </Tag>
            )}
            {data.status === 'SPAM' && (
              <Tag style={{ margin: 0 }} color="red">
                สแปม
              </Tag>
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.9em',
              color: unread ? 'rgba(0, 0, 0, 0.65)' : '#b7b7b7',
              fontWeight: unread ? 700 : 500
            }}
          >
            <span style={{ textOverflow: 'ellipsis' }}>
              {getEventMessage(data.event || {})}
            </span>
            <span style={{ marginLeft: 6 }}>
              • {getChatDate(moment((data.event || {}).timestamp))}
            </span>
          </div>
          <div>
            {
              data.tags && data.tags.map((tag, index) => {
                return <Tag key={index}>{tag}</Tag>
              })
            }
          </div>
        </div>
      </div>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu onClick={handleMenuClick} selectedKeys={data.status}>
            <Menu.Item icon={<EyeInvisibleOutlined />} key="cmd:mark-as-unread">
              <span>ทำเครื่องหมายเป็นยังไม่ได้อ่าน</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.ItemGroup
              title={
                <>
                  <FormOutlined style={{ marginRight: 4 }} />
                  <span>เปลี่ยนสถานะ</span>
                </>
              }
            >
              <Menu.Item key="DEFAULT">
                <span>ทั่วไป</span>
              </Menu.Item>
              <Menu.Item key="FOLLOWED_UP">
                <span>ต้องดำเนินการ</span>
              </Menu.Item>
              <Menu.Item key="DONE">
                <span>ดำเนินการแล้ว</span>
              </Menu.Item>
              <Menu.Item key="SPAM">
                <span>สแปม</span>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
      >
        <div
          style={{
            width: 24,
            marginLeft: 8,
            padding: '0 2px',
            borderRadius: '50%'
          }}
          onClick={() => console.log('click')}
        >
          <EllipsisOutlined
            style={{
              fontWeight: 600,
              fontSize: '1.4em'
            }}
          />
        </div>
      </Dropdown>
    </Container>
  )
}

InboxItem.propTypes = {
  data: PT.shape({
    id: PT.string,
    title: PT.string,
    picture_url: PT.string,
    read: PT.bool,
    platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineModular']),
    status: PT.string,
    event: PT.object
  }),
  selected: PT.bool,
  onClick: PT.func,
  onMarkAsUnread: PT.func,
  onUpdateInbox: PT.func
}

InboxItem.defaultProps = {
  onClick: () => {},
  onUpdateInbox: () => {},
  onMarkAsUnread: () => {}
}

export default InboxItem
