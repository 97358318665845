import ApolloClient from 'apollo-client'
import { split } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'node-fetch'

// const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
let baseUrl = 'https://dev-openapi.aiya.ai/graphql'
let socketUrl = 'wss://dev-openapi.aiya.ai/graphql' 
baseUrl = 'http://localhost:3030/graphql'
socketUrl = 'ws://localhost:3030/graphql'

// let { host } = window.location
// if (process.env.NODE_ENV !== 'development') {
//   baseUrl = `${window.location.protocol}//${host}/graphql`
//   socketUrl = `${protocolPrefix}//${host}/graphql`
// }

const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
// let baseUrl = '/graphql'
// let socketUrl = 'wss://dev-openapi.aiya.ai/graphql'

const { host } = window.location
if (host !== 'localhost:3001') {
  const [env, domain] = host.split('-')

  baseUrl = `${window.location.protocol}//chat-openapi.aiya.ai/graphql`
  socketUrl = `${protocolPrefix}//chat-openapi.aiya.ai/graphql`

  if (env === 'dev' || env === 'uat') {
    baseUrl = `${window.location.protocol}//${env}-openapi.aiya.ai/graphql`
    socketUrl = `${protocolPrefix}//${env}-openapi.aiya.ai/graphql`
  }

  // baseUrl = `${window.location.protocol}//${host}/graphql`
  // socketUrl = `${protocolPrefix}//${host}/graphql`
}

// baseUrl = 'https://aiya.ngrok.io/graphql'
// console.log('>>>>>>>>>>>>>>', baseUrl)
// console.log('>>>>>>>>>>>>>>', socketUrl)


const uploadLink = createUploadLink({ uri: baseUrl })
const wsLink = new WebSocketLink({
  fetch,
  uri: socketUrl,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('bot_token')
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const user_token = localStorage.getItem('user_token')
  const bot_token = localStorage.getItem('bot_token')
  return {
    headers: {
      ...headers,
      'x-aiya-user-token': user_token,
      'x-aiya-bot-token': bot_token,
      Authorization: `Bearer ${bot_token}`
    }
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink
)

const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: link
    .concat(
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        }

        if (networkError) console.log(`[Network error]: ${networkError}`)
      })
    )
    .concat(uploadLink)
})

export { client }
