import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
// import { Icon } from 'antd'

import ImageRenderer from '../../../components/ImageRenderer'
import stickerBook from '../../../constants/stickerBook'

const collections = Object.keys(stickerBook)

const CollectionItem = styled.div`
  ${({ selected }) =>
    selected &&
    `
    background-color: #cccccc;
  `}
  &:hover {
    background-color: #cccccc;
  }
`

const StickerItem = styled.div`
  display: inline-block;
  border-radius: 4px;
  &:hover {
    background-color: #eeeeee;
  }
`

const StickerPanel = ({ onSelect, loading }) => {
  const [currCollection, changeCollection] = React.useState(collections[0])

  const stickerSet = stickerBook[currCollection]

  const collectionUrl = productId => {
    return `https://stickershop.line-scdn.net/stickershop/v1/product/${productId}/ANDROID/tab_on.png`
  }

  const stickerUrl = stickerId => {
    return `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/ANDROID/sticker_key.png`
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div style={{ height: 42, borderTop: '1px solid rgba(0, 0, 0, 0.15)', display: 'flex' }}>
        {(collections || []).map(id => (
          <CollectionItem
            key={id}
            selected={currCollection === id}
            onClick={() => changeCollection(id)}
          >
            <ImageRenderer
              style={{ cursor: 'pointer' }}
              src={collectionUrl(id)}
              alt={id}
              height={41}
            />
          </CollectionItem>
        ))}
      </div>
      <div
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          height: 'calc(100% - 42px)',
          overflow: 'auto'
        }}
      >
        {(stickerSet || []).map(id => (
          <StickerItem
            key={id}
            onClick={() => onSelect({ packageId: currCollection, stickerId: id })}
          >
            <ImageRenderer style={{ cursor: 'pointer' }} src={stickerUrl(id)} alt={id} width={96} />
          </StickerItem>
        ))}
      </div>
      {/* {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.6)'
          }}
        >
          <Icon
            type="loading"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        </div>
      )} */}
    </div>
  )
}

StickerPanel.propTypes = {
  onSelect: PT.func,
  loading: PT.bool
}

StickerPanel.defaultProps = {
  onSelect: () => {}
}

export default StickerPanel
