import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Input, Select, message, Button, Drawer } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useMediaQuery } from 'react-responsive'
import { UnorderedListOutlined } from '@ant-design/icons'

import LINE_GREEN from '../../assets/images/line_64_green.png'
import LINE_GREY from '../../assets/images/line_64_grey.png'
import MAIL_BLUE from '../../assets/images/mail_64_blue.png'
import MAIL_GREY from '../../assets/images/mail_64_grey.png'
import MESSENGER_BLUE from '../../assets/images/messenger_64_blue.png'
import MESSENGER_GREY from '../../assets/images/messenger_64_grey.png'
import color from '../../constants/color'
import Loading from '../../components/Loading'
import useInboxData from '../../hooks/useInboxData'
import useTagData from '../../hooks/useTagData'
import InboxItem from './InboxItem'
import PlatformItem from './PlatformItem'

const MARK_AS_READ = gql`
  mutation markAsRead($inboxId: String!) {
    markAsRead(inbox_id: $inboxId)
  }
`

const MARK_AS_UNREAD = gql`
  mutation markAsUnread($inboxId: String!) {
    markAsUnread(inbox_id: $inboxId)
  }
`

// const UPDATE_PROFILE = gql`
//   mutation updateInbox($inboxId: String!, $input: JSON) {
//     updateInbox(inbox_id: $inboxId, input: $input)
//   }
// `

const UPDATE_FRIEND = gql`
  mutation updateFriend($friendId: String!, $input: FriendInput!) {
    updateFriend(friend_id: $friendId, input: $input)
  }
`
const SEARCH_CHANNEL = gql`
  query searchChannel{
    searchChannel{
      next
      total
      data {
        id
        title
        platform
      }
    }
  }
`

const Inbox = ({ match, history }) => {
  const botId = match.params.botId
  const [showDrawer, setShowDrawer] = useState(false)
  const [channel, setChannel] = useState({
    data: []
  })
  const isMobileOrTablet = useMediaQuery({ maxDeviceWidth: 1224 })

  const platforms = [
    {
      key: 'All',
      title: 'ทั้งหมด',
      icon: { active: MAIL_BLUE, deactive: MAIL_GREY }
    },
    {
      key: 'FacebookBot',
      title: 'Facebook',
      icon: { active: MESSENGER_BLUE, deactive: MESSENGER_GREY }
    },
    {
      key: 'LineBot',
      title: 'LINE',
      icon: { active: LINE_GREEN, deactive: LINE_GREY }
    },
  ]
  const [selectedInbox, setSelectedInbox] = React.useState('')

  const { data: inboxes, loading, loadmore, filters, setFilters } = useInboxData(botId, {
    size: 20
  })
  const { data: tags, loading: fetchingTags } = useTagData(botId)

  const [markAsRead] = useMutation(MARK_AS_READ, { onError: err => console.error(err) })
  const [markAsUnread] = useMutation(MARK_AS_UNREAD, { onError: err => console.error(err) })
  // const [updateInbox] = useMutation(UPDATE_PROFILE, {
  //   onCompleted: () => message.success('บันทึกสำเร็จ'),
  //   onError: err => console.error(err),
  // })

  const [updateFriend] = useMutation(UPDATE_FRIEND, {
    onCompleted: () => message.success('บันทึกสำเร็จ'),
    onError: err => console.error(err)
  })

  useQuery(SEARCH_CHANNEL, {
    fetchPolicy: 'no-cache',
    onError: err => console.error(err),
    onCompleted: ({ searchChannel }) => {
      setChannel(searchChannel)
    }
  })

  const selectedPlatform = filters.platform || 'All'

  const selectedChannelId = filters.channel_id || ''

  const getPlatformColor = platform => {
    switch (platform) {
      case 'LineBot':
      case 'LineModular':
          return color.LINE_THEME
      case 'FacebookBot':
        return color.FB_THEME
      default:
        return color.APP_THEME
    }
  }

  const handleScrollReachBottom = () => {
    loadmore()
  }

  const handleInboxClick = inboxId => {
    setSelectedInbox(inboxId)
    markAsRead({ variables: { inboxId: inboxId } })
    const newUrl = `/${botId}/inbox/chat/${inboxId}`
    history.push(newUrl)
  }

  const handleMarkAsUnread = inboxId => {
    markAsUnread({ variables: { inboxId } })
  }

  const handleUpdateFriend = (friendId, input) => {
    updateFriend({ variables: { friendId, input } })
  }
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      {
        !isMobileOrTablet && (<div style={{ width: 200, borderRight: '1px solid rgba(0, 0, 0, 0.1)', height: '100%' }}>
          {platforms.map(p => (
            <PlatformItem
              key={p.key}
              data={p}
              icon={p.icon}
              color={getPlatformColor(p.key)}
              channelList={channel.data.filter((c) => {
                if (p.key === 'LineBot') {
                  return c.platform === p.key || c.platform === 'LineModular'
                }
                return c.platform === p.key
              })}
              onClickChannel={setFilters}
              selected={p.key === selectedPlatform}
              selectedChannelId={selectedChannelId}
              onClick={() => setFilters({ platform: p.key })}
            />
          ))}
        </div>)
      }
      {
        isMobileOrTablet && (<Drawer
          title="Select Chanel"
          bodyStyle={{ padding: 0 }}
          width='200'
          placement="left"
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
        >
          {platforms.map(p => (
            <PlatformItem
              key={p.key}
              data={p}
              icon={p.icon}
              color={getPlatformColor(p.key)}
              selected={p.key === selectedPlatform}
              channelList={channel.data.filter((c) => {
                if (p.key === 'LineBot') {
                  return c.platform === p.key || c.platform === 'LineModular'
                }
                return c.platform === p.key
              })}
              onClickChannel={setFilters}
              selectedChannelId={selectedChannelId}
              onClick={() => {
                setFilters({ platform: p.key })
              }}
            />
          ))}
        </Drawer>)

      }
      <div style={{ width: isMobileOrTablet ? '100%' : 'calc(100% - 150px)', height: '100%' }}>
        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: '8px 16px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: 8
            }}
          >
            <Select
              style={{ width: 'calc(50% - 4px)' }}
              value={filters.status}
              onChange={val => setFilters({ status: val })}
            >
              <Select.Option key="ALL">ทั้งหมด</Select.Option>
              <Select.Option key="DEFAULT">ทั่วไป</Select.Option>
              <Select.Option key="FOLLOWED_UP">ต้องดำเนินการ</Select.Option>
              <Select.Option key="DONE">ดำเนินการแล้ว</Select.Option>
              <Select.Option key="SPAM">สแปม</Select.Option>
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {
              isMobileOrTablet && (<Button onClick={() => setShowDrawer(true)}
                style={{ position: 'absolute', top: 8, left: 16 }}
              ><UnorderedListOutlined />
              </Button>)
            }

            <Input.Search
              onSearch={s => setFilters({ q: s })}
              placeholder="ค้นหาจากชื่อ"
              style={{ width: 'calc(50% - 4px)', marginRight: 8 }}
            />
            <Select
              placeholder="ค้นหาจากแท็ก"
              value={filters.tags}
              onChange={val => setFilters({ tags: val })}
              mode="tags"
              style={{ width: 'calc(50% - 4px)' }}
              loading={fetchingTags}
            >
              {tags.data && tags.data.map(t => {
                return (<Select.Option key={t.title}>{t.title}</Select.Option>)
              })}
            </Select>
          </div>
        </div>

        <PerfectScrollbar
          style={{ width: '100%', height: 'calc(100% - 90px)', position: 'relative' }}
          onYReachEnd={handleScrollReachBottom}
        >
          {loading && <Loading />}
          {inboxes.map(ib => (
            <InboxItem
              onMarkAsUnread={() => handleMarkAsUnread(ib.id)}
              onUpdateInbox={input => handleUpdateFriend(ib.id, input)}
              selected={ib.id === selectedInbox}
              key={ib.id}
              data={ib}
              onClick={() => handleInboxClick(ib.id)}
            />
          ))}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default Inbox
