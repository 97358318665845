import React from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { message } from 'antd'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import { get } from 'lodash'
import PT from 'prop-types'
import styled from 'styled-components'

import Loading from '../../components/Loading'
import useChatData from '../../hooks/useChatData'
import useTagData from '../../hooks/useTagData'
import ChatHeader from './ChatHeader'
import ChatItem from './ChatItem'
import ComposeBar from './ComposeBar'
import ChatInfo from './ChatInfo'
import DateSnack from './DateSnack'
import KeywordBubble from './KeywordBubble'
import ModalKeyword from './ModalKeyword'
import TabUserInfo from './TabUserInfo'
import TabComment from './TabComment'
import TabOther from './TabOther'
import { useEffect } from 'react'

const ContainerTab = styled.div`
  padding: 20px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
`

const INFO_PANEL_WIDTH = 370

const UPDATE_PROFILE = gql`
  mutation updateFriend($friendId: String!, $input: FriendInput!) {
    updateFriend(friend_id: $friendId, input: $input)
  }
`

const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: Upload!) {
    uploadImage(file: $file) {
      id
      filename
      url
      encoding
      mimetype
    }
  }
`

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      id
      filename
      url
      encoding
      mimetype
    }
  }
`

const PUSH_CHAT = gql`
  mutation pushMessage(
    $chatId: String!
    $messages: [ChatMessageInput!]
    $platform: Platform!
    $keywordId: String
    $trainSentence: String
  ) {
    pushMessage(
      chat_id: $chatId
      messages: $messages
      platform: $platform
      keyword_id: $keywordId
      train_sentence: $trainSentence
    )
  }
`

const TURN_ON_BOT = gql`
  mutation turnOnBot($friendId: String!) {
    turnOnBot(friend_id: $friendId)
  }
`

const TURN_OFF_BOT = gql`
  mutation turnOffBot($friendId: String!) {
    turnOffBot(friend_id: $friendId)
  }
`

const SEARCH_KEYWORD = gql`
  query searchKeyword($filters: KeywordSearchFilter, $page: KeywordSearchPagination) {
    searchKeyword(input: { filters: $filters, page: $page }) {
      total
      next
      data {
        id
        title
        sentences {
          type
          text
        }
      }
    }
  }
`

const GET_SUGGESTION_KEYWORD = gql`
  query getKeywordSuggestion($userId: String!, $lastMessage: String!) {
    getKeywordSuggestion(user_id: $userId, query: $lastMessage) {
      total
      next
      data {
        id
        title
      }
    }
  }
`

const GET_ACTION = gql`
  query getAction($keywordId: String!) {
    getAction(keyword_id: $keywordId) {
      id
      facebook_messages
      line_messages
    }
  }
`

const GET_FRIEND = gql`
  query getFriend($friendId: String!) {
    getFriend(friend_id: $friendId) {
      id
      contact
      tags
      about
      bot_enabled
    }
  }
`

class Chat extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loadingMore: false,
      loading: false,
      showStickerPanel: false,
      learning: true,
      showModalKeyword: false,
      selectedKeyword: '',
      selectedTab: false,
      botEnabled: props.botEnabled
    }
    this.scrollRef = React.createRef({})
    this.isBottom = false
    this.prevHeight = 0
  }

  handleScroll = e => {
    const scrollRatio = this.getScrollRatio(e)
    // scroll reach top
    if (scrollRatio === 0) {
      this.handleScrollReachTop()
    }

    this.isBottom = scrollRatio === 1
  }

  handleScrollReachTop = () => {
    if (this.state.loadingMore) {
      return
    }
    this.setState({ loadingMore: true })
    this.props.loadmore()
  }

  handleSendMessage = async message => {
    const { pushChat, inboxId, data } = this.props
    const platform = data.inbox.platform
    const messages = [message]
    this.setState({ loading: true })
    await pushChat({ variables: { chatId: inboxId, messages, platform } })
    this.setState({ loading: false })
  }

  handleUploadImage = async file => {
    const { uploadImage, pushChat, inboxId, data } = this.props
    this.setState({ loading: true })
    const result = await uploadImage(file)
    const platform = data.inbox.platform
    let messages = []
    if (platform === 'FacebookBot') {
      messages = [
        {
          attachment: {
            type: 'image',
            payload: {
              url: result.data.uploadImage.url
            }
          }
        }
      ]
    } else {
      messages = [
        {
          previewImageUrl: result.data.uploadImage.url,
          originalContentUrl: result.data.uploadImage.url,
          type: 'image'
        }
      ]
    }
    await pushChat({ variables: { chatId: inboxId, messages, platform } })
    this.setState({ loading: false })
  }

  handleUploadFile = async file => {
    const { uploadFile, pushChat, inboxId, data } = this.props
    this.setState({ loading: true })
    const result = await uploadFile(file)
    const platform = data.inbox.platform
    let messages = []
    if (["LineBot", "LineModular"].includes(platform)) {
      messages = [
        {
          type: 'custom',
          template: 'file',
          message: {
            type: 'flex',
            altText: 'Sent a file.',
            contents: {
              type: 'bubble',
              direction: 'ltr',
              body: {
                type: 'box',
                layout: 'vertical',
                action: {
                  type: 'uri',
                  label: 'open file',
                  uri: result.data.uploadFile.url
                },
                contents: [
                  {
                    type: 'box',
                    layout: 'vertical',
                    contents: [
                      {
                        type: 'text',
                        text: result.data.uploadFile.filename
                      }
                    ]
                  }
                ]
              }
            }
          },
          data: {
            ...result.data.uploadFile
          }
        }
      ]
    } else {
      messages = [
        {
          attachment: {
            type: 'file',
            payload: {
              url: result.data.uploadFile.url
            }
          }
        }
      ]
    }
    await pushChat({ variables: { chatId: inboxId, messages, platform } })
    this.setState({ loading: false })
  }

  handleUpdateProfile = async input => {
    const { inboxId, updateInbox } = this.props
    this.setState({ loading: true })
    await updateInbox({ variables: { friendId: inboxId, input } })
    this.setState({ loading: false })
  }

  handleToggleBot = async status => {
    const { inboxId, turnOnBot, turnOffBot, setBotEnabled, botEnabled } = this.props
    this.setState({ loading: true })
    console.log('botEnabled', botEnabled );
    setBotEnabled(!botEnabled)

    if (status) {
      await turnOnBot({ variables: { friendId: inboxId } })
      localStorage.setItem(inboxId, JSON.stringify({
        botEnabled: true,
      }))
    } else {
      await turnOffBot({ variables: { friendId: inboxId } })
      localStorage.setItem(inboxId, JSON.stringify({
        botEnabled: false,
        expiredAt: moment().add(5, 'minute').format()
      }))

      setTimeout(() => {
        setBotEnabled(true)
        localStorage.setItem(inboxId, JSON.stringify({
          botEnabled: true,
        }))
      }, 1000 * 60 * 5)
    }
    this.setState({ loading: false })
  }

  handleLearning = value => {
    this.setState({
      learning: value
    })
  }

  handleSetStickerPanel = value => {
    if (typeof value === 'object') {
      this.setState(
        {
          showStickerPanel: !this.state.showStickerPanel
        },
        () => {
          if (this.state.showStickerPanel) {
            setTimeout(() => {
              this.scrollRef.current.scrollTop = get(this, 'scrollRef.current.scrollHeight')
            }, 200)
          }
        }
      )
    } else {
      if (typeof value === 'boolean') {
        this.setState(
          {
            showStickerPanel: value
          },
          () => {
            if (value) {
              setTimeout(() => {
                if (this.scrollRef.current) {
                  this.scrollRef.current.scrollTop = get(this, 'scrollRef.current.scrollHeight')
                }
              }, 200)
            }
          }
        )
      }
    }
  }

  handleSendSticker = async d => {
    const { pushChat, inboxId, data } = this.props
    const platform = data.inbox.platform
    const messages = [
      {
        type: 'sticker',
        ...d
      }
    ]
    this.setState({ loading: true })
    await pushChat({ variables: { chatId: inboxId, messages, platform } })
    this.handleSetStickerPanel(false)
    this.setState({ loading: false })
  }

  handleSendKeyword = async ({ keywordId, isLearn }) => {
    const { pushChat, inboxId, data, userLastMessage } = this.props
    const platform = data.inbox.platform
    const variables = {
      chatId: inboxId,
      keywordId,
      platform,
      trainSentence: userLastMessage
    }

    if (!isLearn) {
      delete variables.trainSentence
    }

    this.setState({ loading: true })
    await pushChat({
      variables
    })
    this.setState({ loading: false })
  }

  handleImageRenderCompleted = () => {
    if (this.isBottom) {
      this.scrollToBottom()
    }
  }

  notifyMessage = () => {
    message.info('You have a new message')
  }

  scrollToBottom = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollTop = get(this, 'scrollRef.current.scrollHeight')
    }
    this.isBottom = true
  }

  getScrollRatio = ref => {
    if (!ref) {
      return
    }
    return ref.scrollTop / (ref.scrollHeight - ref.clientHeight)
  }

  backToInboxList = () => {
    if (this.state.selectedTab) {
      this.setState({
        selectedTab: false
      })
    } else {
      const botId = this.props.match.params.botId
      this.props.history.push(`/${botId}/inbox/list`)
    }
  }

  componentDidUpdate (prevProps) {
    const { data, inboxId } = this.props
    const oldChats = prevProps.data.chats
    const newChats = data.chats


    if (inboxId !== prevProps.inboxId) {
      this.scrollToBottom()
      this.prevHeight = get(this, 'scrollRef.current.scrollHeight')
    } else if (oldChats !== newChats) {
      // chats changed
      // first time fetch completed
      if (oldChats.length === 0) {
        this.scrollToBottom()
        this.prevHeight = get(this, 'scrollRef.current.scrollHeight')

        const {botEnabled, expiredAt} = JSON.parse(localStorage.getItem(inboxId) || "{\"botEnabled\": true}")

        if (expiredAt && moment(expiredAt) > moment()) {
          this.setState({botEnabled})
          setTimeout(() => {
            this.setState({botEnabled: true})
            localStorage.removeItem(inboxId)
          }, moment(expiredAt).valueOf() - moment().valueOf())
        } else {
          localStorage.removeItem(inboxId)
        }
      } else if (
        // new message coming from subscribe
        JSON.stringify(newChats[newChats.length - 1]) !==
        JSON.stringify(oldChats[oldChats.length - 1])
      ) {
        // if scrollbar is at bottom or not
        if (this.isBottom) {
          this.scrollToBottom()
        } else {
          this.notifyMessage()
        }
      } else if (oldChats.length !== 0 && newChats.length !== oldChats.length) {
        // new message coming from loadmore
        this.scrollRef.current.scrollTop = get(this, 'scrollRef.current.scrollHeight') - this.prevHeight
        this.prevHeight = get(this, 'scrollRef.current.scrollHeight')
        this.setState({ loadingMore: false })
      }
    }
  }

  setShowModalKeyword = (value) => {
    this.setState({
      showModalKeyword: value
    })
  }

  handleSelectedKeyword = async (keywordId) => {
    this.setState({
      selectedKeyword: keywordId
    })
    await this.props.onGetAction({ variables: { keywordId } })
  }

  confirmModal = (isLearn) => {
    this.handleSendKeyword({ keywordId: this.state.selectedKeyword, isLearn })
    this.setShowModalKeyword(false)
    this.setState({
      selectedKeyword: ''
    })
  }

  closeModal = async () => {
    await this.props.onGetAction({ variables: { keywordId: '' } })
    this.setShowModalKeyword(false)
    this.setState({
      selectedKeyword: ''
    })
  }

  handleClickBubleButton = async (keywordId) => {
    this.setState({
      selectedKeyword: keywordId,
      showModalKeyword: true
    })
    await this.props.onGetAction({ variables: { keywordId } })
  }

  handleSelectedTab = (tab) => {
    const { isDesktop } = this.props
    if (!isDesktop && tab) {
      this.setState({
        selectedTab: tab
      })
    } else {
      this.setState({
        selectedTab: false
      })
    }
  }

  render () {
    const { isDesktop, botTags, keywordList, suggestionKeywordList, previewMessage, contact, userLastMessage, botEnabled } = this.props
    const { inbox, chats } = this.props.data
    const { loading, learning, showStickerPanel, selectedTab } = this.state
    let reduceChatScreenHeight = 60 + 72
    if (showStickerPanel) {
      reduceChatScreenHeight += 320
    }

    if (learning && suggestionKeywordList.total) {
      reduceChatScreenHeight += 50
    }

    const lastChat = chats[chats.length - 1]


    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            width: `calc(100% - ${isDesktop ? INFO_PANEL_WIDTH : 0}px)`,
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <ChatHeader
            data={inbox}
            botTags={botTags}
            loading={loading}
            onUpdateProfile={this.handleUpdateProfile}
            onBack={this.backToInboxList}
            handleSelectedTab={this.handleSelectedTab}
            onToggleBot={this.handleToggleBot}
            learnging={learning}
            onToggleLearning={this.handleLearning}
            botEnabled={botEnabled}
          />
          {
            !selectedTab && (<PerfectScrollbar
              containerRef={ref => (this.scrollRef.current = ref)}
              style={{
                height: `calc(100% - ${reduceChatScreenHeight}px)`,
                transition: 'height 0.2s ease-out'
              }}
              onScrollY={this.handleScroll}
            >
              <div style={{ padding: '0 16px' }}>
                {chats.map((chat, index) => {
                  const prevChat = index === 0 ? null : chats[index - 1]
                  let isShowDate = false
                  if (chat && prevChat) {
                    isShowDate =
                      prevChat &&
                      moment(Number(chat.timestamp)).format('DDD') !==
                        moment(Number(prevChat.timestamp)).format('DDD')
                  }
                  return (
                    <div key={chat.id}>
                      {isShowDate && <DateSnack timestamp={chat.timestamp} />}
                      <ChatItem
                        key={chat.id}
                        data={chat}
                        platform={inbox.platform}
                        chat_picture={inbox.picture_url}
                        onImageLoaded={this.handleImageRenderCompleted}
                      />
                    </div>
                  )
                })}
              </div>
            </PerfectScrollbar>
            )
          }

          { !selectedTab && learning && suggestionKeywordList.total ? (
            <KeywordBubble clickButton={this.handleClickBubleButton} data={suggestionKeywordList.data} />
          ) : ''}

          {
            !selectedTab && (<ComposeBar
              setStickerPanel={this.handleSetStickerPanel}
              platform={inbox.platform}
              onSend={this.handleSendMessage}
              uploadImage={this.handleUploadImage}
              uploadFile={this.handleUploadFile}
              sendSticker={this.handleSendSticker}
              setShowModalKeyword={this.setShowModalKeyword}
              disabled={inbox.platform === 'FacebookBot' && lastChat && moment().diff(lastChat?.timestamp, 'hour') > 24}
            />)
          }
          <ContainerTab>
            {
              selectedTab === 'tabComment' && (<TabComment
                userId={inbox.id}
              />)
            }
            {
              selectedTab === 'tabUserInfo' && (<TabUserInfo
                botTags={botTags}
                data={contact}
                onUpdateProfile={this.handleUpdateProfile}
              />)
            }
            {
              selectedTab === 'tabOther' && (<TabOther
                userId={inbox.id}
              />)
            }
          </ContainerTab>
        </div>
        {isDesktop && (
          <div
            style={{
              width: INFO_PANEL_WIDTH,
              height: '100%',
              borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
              overflow: 'scroll'
            }}
          >
            <ChatInfo
              botTags={botTags}
              loading={loading}
              inbox={inbox}
              onUpdateProfile={this.handleUpdateProfile}
              contact={contact}
            />
          </div>
        )}
        <ModalKeyword
          keywordList={keywordList}
          previewMessage={previewMessage}
          platform={inbox.platform}
          showModalKeyword={this.state.showModalKeyword}
          setShowModalKeyword={this.setShowModalKeyword}
          handleSelectedKeyword={this.handleSelectedKeyword}
          confirmModal={this.confirmModal}
          selectedKeyword={this.state.selectedKeyword}
          userLastMessage={userLastMessage}
          closeModal={this.closeModal}
        />
      </div>
    )
  }
}

Chat.propTypes = {
  inboxId: PT.string,
  updateInbox: PT.func,
  createComment: PT.func,
  turnOnBot: PT.func,
  turnOffBot: PT.func,
  data: PT.object,
  isDesktop: PT.bool,
  botTags: PT.array,
  uploadFile: PT.func,
  pushChat: PT.func,
  uploadImage: PT.func,
  loadmore: PT.func,
  keywordList: PT.object,
  suggestionKeywordList: PT.object,
  history: PT.object,
  botEnabled: PT.bool,
  setBotEnabled: PT.func
}

export default props => {
  const { match } = props
  const botId = match.params.botId
  const inboxId = match.params.inboxId
  const [botEnabled, setBotEnabled] = React.useState(true)
  const [contact, setContact] = React.useState({})
  const [keywordList, setKeywordList] = React.useState({
    total: '',
    next: '',
    data: []
  })

  const [suggestionKeywordList, setSuggestionKeywordList] = React.useState({
    total: '',
    next: '',
    data: []
  })

  const [previewMessage, setPreviewMessage] = React.useState({
    facebook_messages: [],
    line_messages: []
  })
  const { data: tags, loading: fetchingTags } = useTagData(botId)
  const { data, loading, loadmore } = useChatData(botId, inboxId, { size: 40, loadmoreSize: 20 })
  const [pushChat] = useMutation(PUSH_CHAT, { onError: err => console.error(err) })
  const [uploadImage] = useMutation(UPLOAD_IMAGE, { onError: err => console.error(err) })
  const [uploadFile] = useMutation(UPLOAD_FILE, { onError: err => console.error(err) })
  const [updateFriend] = useMutation(UPDATE_PROFILE, {
    onError: err => console.error(err),
    onCompleted: ({ updateFriend }) => {
      setContact(updateFriend)
      message.success('บันทึกสำเร็จ')
    }
  })

  const [onGetAction] = useLazyQuery(GET_ACTION, {
    onError: (err) => {
      console.error(err)
      setPreviewMessage({
        facebook_messages: [],
        line_messages: []
      })
    },
    onCompleted: ({ getAction }) => {
      setPreviewMessage(getAction)
    }
  })

  const [turnOnBot] = useMutation(TURN_ON_BOT, {
    onError: err => console.error(err),
    onCompleted: () => message.success('บันทึกสำเร็จ')
  })

  const [turnOffBot] = useMutation(TURN_OFF_BOT, {
    onError: err => console.error(err),
    onCompleted: () => message.success('บันทึกสำเร็จ')
  })

  useQuery(GET_FRIEND, {
    fetchPolicy: 'no-cache',
    variables: { friendId: inboxId },
    onError: err => console.error(err),
    onCompleted: ({ getFriend }) => {
      setContact(getFriend)
      setBotEnabled(getFriend.bot_enabled)
    }
  })

  useQuery(SEARCH_KEYWORD, {
    fetchPolicy: 'no-cache',
    variables: { filters: { source: 'MAP' } },
    onError: err => console.error(err),
    onCompleted: ({ searchKeyword }) => {
      setKeywordList(searchKeyword)
    }
  })

  const reversedChats = [...data.chats]?.reverse()
  const lastMessage = reversedChats?.find(chat => chat.source?.type === 'USER')?.message?.text
  useQuery(GET_SUGGESTION_KEYWORD, {
    fetchPolicy: 'no-cache',
    variables: { userId: inboxId, lastMessage },
    skip: !lastMessage,
    onError: err => console.error(err),
    onCompleted: data => {
      if (data) {
        const { getKeywordSuggestion } = data
        setSuggestionKeywordList(getKeywordSuggestion)
      }
    }
  })

  const isDesktop = useMediaQuery({ minDeviceWidth: 1224 })
  return (
    <>
      {(loading || fetchingTags) && <Loading />}
      <Chat
        {...props}
        inboxId={inboxId}
        data={data}
        botTags={tags.data || []}
        isDesktop={isDesktop}
        loadmore={loadmore}
        pushChat={pushChat}
        uploadImage={uploadImage}
        uploadFile={uploadFile}
        updateInbox={updateFriend}
        turnOnBot={turnOnBot}
        turnOffBot={turnOffBot}
        onGetAction={onGetAction}
        previewMessage={previewMessage}
        keywordList={keywordList}
        suggestionKeywordList={suggestionKeywordList}
        userLastMessage={lastMessage}
        contact={contact}
        botEnabled={botEnabled}
        setBotEnabled={setBotEnabled}
      />
    </>
  )
}
