export const qs2obj = qs => {
  if (!qs) {
    return {}
  }
  let tmpStr = qs.replace('?', '')
  let tmpArr = tmpStr.split('&')
  let tmpObj = {}
  tmpArr.forEach(item => {
    const field = item.split('=')
    tmpObj = { ...tmpObj, [field[0]]: field[1] }
  })
  return tmpObj
}

export const obj2qs = obj => {
  let tmpArr = Object.keys(obj).map(key => `${key}=${obj[key]}`)
  let tmpStr = '?' + tmpArr.join('&')
  return tmpStr
}
