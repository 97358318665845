export const truncate = function (fullStr, strLen = '20', separator) {
  if (fullStr.length <= strLen) return fullStr

  separator = separator || '...'

  var sepLen = separator.length
  var charsToShow = strLen - sepLen
  var frontChars = Math.ceil(charsToShow / 2)
  var backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) +
           separator +
           fullStr.substr(fullStr.length - backChars)
}
