import { useState } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const ALL_CHATS = gql`
  query allChats($inboxId: String!, $page: ChatSearchPagination, $filters: ChatSearchFilter) {
    getInbox(inbox_id: $inboxId) {
      id
      title
      picture_url
      platform
      tags
      bot_disabled
      status
    }
    searchChat(input: {
      inbox_id: $inboxId,
      page: $page
      filters: $filters
    }) {
      total
      next
      data {
        id
        bot_id
        channel_id
        chat_id
        message
        postback {
          title
          data
        }
        source {
          type
          name
          userId
          id
        }
        timestamp
        type
      }

    }
  }
`

const ON_INBOX_CHANGED = gql`
  subscription onInboxChanged($inboxId: String) {
    onInboxChanged(filters: { inbox_id: $inboxId }) {
      operation
      data {
        id
        chat_id
        bot_id
        title
        type
        picture_url
        tags
        creation
        platform
        status
        event
        read
        bot_disabled
      }
    }
  }
`

const ON_CHAT_CHANGED = gql`
  subscription onChatChanged($chatId: String!) {
    onChatChanged(chat_id: $chatId) {
      operation
      data {
        id
        channel_id
        chat_id
        type
        platform
        timestamp
        source {
          type
          id
          name
          userId
        }
        message
        postback {
          title
          data
        }
      }
    }
  }
`

const useChatData = (botId, inboxId, opts = {}) => {
  const [loading, setLoading] = useState(true)
  const [loadmoreCallback, setLoadmoreCallback] = useState(undefined)
  const [page, setPage] = useState({ size: opts.size, start: undefined })
  const [store, setStore] = useState({
    inbox: {},
    chats: []
  })
  const [tunnel, setTunnel] = useState('')

  const setValueToStore = items => {
    setStore({ ...store, ...items })
  }

  const loadmore = cb => {
    if (store.next == null || loading) {
      return
    }
    setTunnel('loadmore')
    setLoading(true)
    setLoadmoreCallback(cb)
    setPage({
      size: opts.loadmoreSize || opts.size,
      start: store.next
    })
  }

  useQuery(ALL_CHATS, {
    fetchPolicy: 'no-cache',
    variables: { inboxId, page },
    onError: err => console.error(err),
    onCompleted: ({ searchChat, getInbox }) => {
      if (loadmoreCallback) {
        loadmoreCallback()
        setLoadmoreCallback(undefined)
      }
      if (tunnel === 'loadmore') {
        setValueToStore({
          inbox: getInbox,
          chats: [...store.chats, ...searchChat.data],
          next: searchChat.next
        })
        setTunnel('')
      } else {
        setValueToStore({
          inbox: getInbox,
          chats: searchChat.data,
          next: searchChat.next
        })
        setPage({ size: opts.size, start: undefined })
      }
      setLoading(false)
    }
  })

  useSubscription(ON_INBOX_CHANGED, {
    variables: { inboxId },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onInboxChanged = {} }
      } = subscriptionData
      const { data: newInbox, operation } = onInboxChanged
      const tmp = { ...newInbox }
      delete tmp.bot_id
      delete tmp.doctype
      delete tmp.op
      if (operation === 'UPDATE') {
        // if (tmp.bot) {
        //   tmp.bot_disabled = tmp.bot.disabled === 1
        //   delete tmp.bot
        // }
        setValueToStore({ inbox: { ...store.inbox, ...tmp } })
      }
    }
  })

  useSubscription(ON_CHAT_CHANGED, {
    variables: { botId, chatId: inboxId },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onChatChanged = {} }
      } = subscriptionData
      const { data: newChat } = onChatChanged
      const tmp = { ...newChat }
      delete tmp.bot_id
      delete tmp.doctype
      delete tmp.op
      setValueToStore({ chats: [...store.chats, tmp] })
    }
  })

  return {
    data: { ...store, chats: store.chats.sort((a, b) => a.timestamp - b.timestamp) },
    loading,
    loadmore
  }
}

export default useChatData
