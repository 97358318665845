import React from 'react'
import PT from 'prop-types'
import { Button } from 'antd'

const KeywordBubble = ({ data, clickButton }) => {
  return (
    <div style={{ padding: '5px 10px', overflow: 'scroll', display: 'flex' }}>
      {data.slice(0, 5).map((d, index) => {
        return (
          <Button
            onClick={() => clickButton(d.id)}
            shape="round"
            key={index}
            size="sm"
            style={{ margin: '0 5px 5px 0' }}
          >
            {d.title}
          </Button>
        )
      })}
    </div>
  )
}

KeywordBubble.propTypes = {
  data: PT.array,
  clickButton: PT.func
}
export default KeywordBubble
