import React from 'react'
import PT from 'prop-types'
import moment from 'moment'
import { Input, Divider, Button, Modal } from 'antd'
import useActivityData from '../../hooks/useActivityData'

const TabComment = ({
  userId
}) => {
  const { activityList, createComment } = useActivityData(userId, 'Comment')

  const [commentInput, setCommentInput] = React.useState({
    message: '',
    user_id: userId
  })

  React.useEffect(() => {
    setCommentInput({
      message: '',
      user_id: userId
    })
  }, [userId])

  const handleClickCreateComment = async () => {
    Modal.confirm({
      title: 'คุณต้องการจะบักทึกเพิ่มเติมใช่หรือไม่',
      onOk: confirmCreateComment,
      cancelText: 'ยกเลิก',
      okText: 'ตกลง'
    })
  }

  const confirmCreateComment = async () => {
    try {
      await createComment({ variables: { input: commentInput } })
      setCommentInput({
        message: '',
        user_id: userId
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (<div>
    <div>
      <div>บันทึกเพิ่มเติม</div>
      <div style={{ marginBottom: '6px' }}>
        <Input.TextArea value={commentInput.message} onChange={(e) => setCommentInput({ ...commentInput, message: e.target.value })} />
      </div>
      <Button type="primary" onClick={handleClickCreateComment}>บันทึก</Button>
    </div>
    <Divider />
    {
      activityList.data.length > 0 ? (<div style={{ width: '100%' }}>
        <div style={{ color: '#707070', marginBottom: '7px' }}>ประวัติบันทึกเพิ่มเติม</div>
        {
          activityList.data.map((activity, index) => {
            return <div key={index} style={{ marginBottom: '20px' }}>
              <pre>
                {activity.message}
              </pre>
              <div>
                <span style={{ color: 'rgba(46, 47, 48, 0.5)' }}>โดย {activity.name} เมื่อ {moment(activity.creation).format('DD/MM/YYYY HH:mm')}</span>
              </div>
              <hr />
            </div>
          })
        }
      </div>) : ''
    }
  </div>
  )
}

TabComment.propTypes = {
  userId: PT.string
}
export default React.memo(TabComment)
