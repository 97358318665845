import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { qs2obj } from './helpers/queryStr'

const LOGIN = gql`
  mutation login($uid: String!, $botId: String!) {
    login(uid: $uid, bot_id: $botId)
  }
`

const Portal = ({ location, history }) => {
  const params = qs2obj(location.search)
  const [login, { called }] = useMutation(LOGIN, {
    onError: err => console.error(err),
    onCompleted: ({ login }) => {
      localStorage.setItem('user_token', login.user_token)
      localStorage.setItem('bot_token', login.bot_token)
      history.replace(`/${params.bot_id}/inbox/list`)
    },
  })

  React.useEffect(() => {
    if (params.token) {
      const tmp = JSON.parse(atob(params.token))
      localStorage.setItem('user_token', tmp.u)
      localStorage.setItem('bot_token', tmp.b)
      history.replace(`/${tmp.id}/inbox/list`)
    }
    if (!called) {
      login({ variables: { uid: params.uid, botId: params.bot_id } })
    }
  }, [login, params, called, history])

  return <div>Loading..</div>
}

export default Portal
