import { useState } from 'react'
import { useMutation, useSubscription, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message } from 'antd'

const CREATE_COMMENT = gql`
  mutation createCommentActivity($input: CommentActivityInput!) {
    createCommentActivity(input: $input)
    {
      id
      service
    }
  }
`

const ON_ACTIVITY_CHANGED = gql`
  subscription onActivityChanged($filters: ActivitySubscriptionFilter) {
    onActivityChanged(filters: $filters) {
      operation
      data {
        id
        service
        name
        user_id
        message
        body
        created_by
        creation
      }
    }
  }
`

const SEARCH_ACTIVITY = gql`
query searchActivity($filters: ActivitySearchFilter, $page: ActivitySearchPagination) {
  searchActivity(input: {
    filters: $filters, page: $page
  }) {
    total
    next
    data {
      id
      service
      body
      message
      created_by
      user_id
      name
      creation
    }
  }
}
`

const useActivityData = (userId, service) => {
  const [createComment] = useMutation(CREATE_COMMENT, {
    onError: err => console.error(err),
    onCompleted: () => message.success('บันทึกสำเร็จ')
  })

  useQuery(SEARCH_ACTIVITY, {
    fetchPolicy: 'no-cache',
    onError: err => console.error(err),
    variables: {
      filters: { service: service, user_id: userId }
    },
    skip: !userId,
    onCompleted: (result) => {
      if (!result?.searchActivity?.data) {
        return
      }
      setActivityList(result?.searchActivity)
    }
  })

  const [activityList, setActivityList] = useState({
    total: 0,
    next: '',
    data: []
  })

  useSubscription(ON_ACTIVITY_CHANGED, {
    variables: { filters: { service, user_id: userId } },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onActivityChanged = {} }
      } = subscriptionData
      const newActivityList = [onActivityChanged.data, ...activityList.data]
      setActivityList({
        ...activityList,
        data: newActivityList
      })
    }
  })

  return {
    activityList,
    createComment
  }
}

export default useActivityData
