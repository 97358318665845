import React from 'react'
import PT from 'prop-types'

import TextMessage from './TextMessage'
import ImageMessage from './ImageMessage'
import VideoMessage from './VideoMessage'
import TemplateCardMessage from './TemplateCardMessage'
import FileMessage from './FileMessage'

const MessageRender = ({ botId, channelId, message, onImageLoaded, platform, bubbleColor }) => {
  if (["LineBot", "LineModular"].includes(platform)) {
    if (message.type === 'text') {
      return <TextMessage text={message.text} bubbleColor={bubbleColor} />
    } else if (message.type === 'image') {
      return <ImageMessage imageUrl={message.url || message.previewImageUrl} onLoaded={onImageLoaded} />
    } else if (message.type === 'video') {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        sources: [{
          src: message.url,
          type: 'video/mp4'
        }]
      }
      return <VideoMessage { ...videoJsOptions } />
    } else if (message.type === 'file') {
      return <FileMessage text={message?.fileName} url={message.url || `https://drive.aiya.ai/b/${botId}/content/${message?.id}`} bubbleColor={bubbleColor} />
    } else if (message.type === 'custom') {
      return <FileMessage text={message.data.filename} url={message.data.url} bubbleColor={bubbleColor} />
    } else if (message.type === 'sticker') {
      const url = `https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/ANDROID/sticker.png`
      return <ImageMessage imageUrl={url} onLoaded={onImageLoaded} />
    } else {
      return <TextMessage text={`[${message.type}]`} bubbleColor={bubbleColor} />
    }
  }

  if (platform === 'FacebookBot') {
    if (message.text) {
      return <TextMessage text={message.text} bubbleColor={bubbleColor} />
    } 
    else if (message.type === 'image') {
      return <ImageMessage imageUrl={message.url} onLoaded={onImageLoaded} />
    } else if (message.type === 'video') {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        sources: [{
          src: message.url,
          type: 'video/mp4'
        }]
      }
      return <VideoMessage { ...videoJsOptions } />
    }
    else if (message.attachment) {
      const attachmentType = message.attachment.type
      const attachmentPayload = message.attachment.payload || {}

      if (attachmentType === 'image') {
        const imageUrl = attachmentPayload.url || `https://s3.ap-southeast-1.amazonaws.com/dev.admin.aiya.ai/${botId}/images/facebook/${attachmentPayload.attachment_id}`
        return <ImageMessage imageUrl={imageUrl} onLoaded={onImageLoaded} />
      } else if (attachmentType === 'template') {
        return (
          <TemplateCardMessage
            ratio={attachmentPayload.image_aspect_ratio === 'square' ? 'square' : 'fb_horizon'}
            data={(attachmentPayload.elements || []).map((el, i) => ({
              key: i,
              title: el.title,
              description: el.subtitle,
              imageUrl: el.image_url,
              buttons: el.buttons.map(btn => btn.title)
            }))}
          />
        )
      } else if (attachmentType === 'file') {
        return (<FileMessage
          text={attachmentPayload.url}
        />)
      }
      return <TextMessage text={`[${message.attachment.type}]`} bubbleColor={bubbleColor} />
    }
  }

  return (
    <TextMessage
      text="Unable to render"
      style={{ fontStyle: 'italic' }}
      bubbleColor={bubbleColor}
    />
  )
}

MessageRender.propTypes = {
  botId: PT.string,
  bubbleColor: PT.shape({
    text: PT.string,
    backgroundColor: PT.string
  }),
  channelId: PT.string,
  message: PT.object,
  onImageLoaded: PT.func,
  platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineModular'])
}

MessageRender.defaultProps = {
  onImageLoaded: () => {}
}

export default MessageRender
