import React from 'react'
import PT from 'prop-types'

const TextMessage = ({ text, style, bubbleColor }) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        padding: '4px 12px',
        color: bubbleColor.text,
        backgroundColor: bubbleColor.background,
        borderRadius: '16px',
        ...style,
      }}
    >
      {text}
    </div>
  )
}

TextMessage.propTypes = {
  text: PT.string,
  bubbleColor: PT.shape({
    text: PT.string,
    background: PT.string,
  }),
  style: PT.object,
}

TextMessage.defaultProps = {
  text: '',
  bubbleColor: {
    text: 'rgba(0, 0, 0, 0.65)',
    background: '#dddddd',
  },
  style: {},
}

export default TextMessage
