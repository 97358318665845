import { useState } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const FETCH_TAGS = gql`
  query searchTag {
    searchTag(input: { page: { size: 1000 }}) {
      data {
        title
      }
    }
  }
`

const SUBSCRIBE_TAG = gql`
  subscription onTagChanged {
    onTagChanged{
      id
      title
    }
  }
`

const useChatData = botId => {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState({})

  useQuery(FETCH_TAGS, {
    fetchPolicy: 'no-cache',
    onError: err => console.error(err),
    onCompleted: ({ searchTag }) => {
      setTags(searchTag)
      setLoading(false)
    }
  })

  useSubscription(SUBSCRIBE_TAG, {
    variables: { botId },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onTagChanged: newTag = {} }
      } = subscriptionData
      if (newTag.op === 'CREATE') {
        const tagToCreate = newTag.title
        const newTags = [tagToCreate, ...tags]
        setTags(newTags)
      } else if (newTag.op === 'DELETE') {
        const tagToDelete = ''
        const newTags = tags.filter(t => t !== tagToDelete)
        setTags(newTags)
      }
    }
  })

  return { data: tags, loading }
}

export default useChatData
