import React from 'react'
import PT from 'prop-types'
import { Input, message } from 'antd'
import { SendOutlined, PaperClipOutlined, SmileOutlined, PartitionOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import StickerPanel from './MessageRender/StickerPanel'

const ComposeBar = ({
  onSend,
  platform,
  uploadFile,
  uploadImage,
  setStickerPanel,
  sendSticker,
  setShowModalKeyword,
  disabled
}) => {
  const [input, setInput] = React.useState('')

  const imageType = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp'
  ]

  const uploadInput = React.createRef()

  const openUpload = () => {
    setStickerPanel(false)
    uploadInput.current.click()
  }

  const handleSubmitInputText = () => {
    const message = {
      type: ["LineBot", "LineModular"].includes(platform) ? 'text' : undefined,
      text: input
    }
    onSend(message)
    setInput('')
  }

  const handleUploadFile = async e => {
    const file = e.target.files[0]
    console.log('file ==.>>>', file)
    if (["LineBot", "LineModular"].includes(platform)) {
      if (file.size > 25000000 && imageType.includes(file.type)) {
        message.error('ไฟล์มีขนาดใหญ่เกินกว่าที่กำหนด')
        return
      } else if (file.size > 10000000) {
        message.error('ไฟล์มีขนาดใหญ่เกินกว่าที่กำหนด')
        return
      }
    }

    if (!!!["LineBot", "LineModular"].includes(platform) && file.size > 25000000) {
      message.error('ไฟล์มีขนาดใหญ่เกินกว่าที่กำหนด')
      return
    }
    // setLoading(true)
    try {
      if (['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        await uploadImage({
          variables: {
            file
          }
        })
      } else {
        await uploadFile({
          variables: {
            file
          }
        })
      }
    } catch (err) {
      console.error(err)
    }
    // setLoading(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)'
        }}
      >
        <Input.TextArea
          style={{ borderRadius: 8, width: 'calc(100% - 32px)', resize: 'none', marginRight: 8 }}
          rows={5}
          size="large"
          autoSize={{ minRows: 2 }}
          value={input}
          onFocus={() => setStickerPanel(false)}
          onChange={e => setInput(e.target.value)}
          onPressEnter={e => (e.metaKey || e.shiftKey) && handleSubmitInputText()}
        />
        <IconBtn onClick={handleSubmitInputText}>
          <SendOutlined style={{ fontSize: '1.5em' }} />
        </IconBtn>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={uploadInput}
          onChange={handleUploadFile}
        />
        <IconBtn onClick={openUpload}>
          <PaperClipOutlined style={{ fontSize: '1.5em' }}/>
        </IconBtn>
        {
          (platform === 'LineBot' || platform === 'LineModular') && (<IconBtn onClick={setStickerPanel}>
            <SmileOutlined style={{ fontSize: '1.5em' }} />
          </IconBtn>)
        }
        <IconBtn onClick={() => setShowModalKeyword(true)}>
          <PartitionOutlined style={{ fontSize: '1.5em' }} />
        </IconBtn>
      </div>
      <div style={{ width: '100%', height: 320 }}>
        <StickerPanel onSelect={(value) => sendSticker(value)}/>
      </div>
      {
        disabled && (
          <Mirror>
            DISABLE
          </Mirror>
        )
      }
    </div>
  )
}

ComposeBar.propTypes = {
  onSend: PT.func,
  uploadFile: PT.func,
  uploadImage: PT.func,
  setStickerPanel: PT.func,
  sendSticker: PT.func,
  setShowModalKeyword: PT.func,
  platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineModular'])
}

ComposeBar.defaultProps = {
  onSend: () => {}
}

export default ComposeBar

export const IconBtn = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ circle }) => (circle ? '50%' : '4px')};
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`

const Mirror = styled.div`
  position: absolute;
  text-align:center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(211,211,211,0.5);
  padding-top: 30px;

`
