import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <LoadingOutlined style={{ marginRight: 8 }} />
        <span>Loading</span>
      </span>
    </div>
  )
}

export default Loading
