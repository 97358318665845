export default {
  6359: [
    '11069848',
    '11069849',
    '11069850',
    '11069851',
    '11069852',
    '11069853',
    '11069854',
    '11069855',
    '11069856',
    '11069857',
    '11069858',
    '11069859',
    '11069860',
    '11069861',
    '11069862',
    '11069863',
    '11069864',
    '11069865',
    '11069866',
    '11069867',
    '11069868',
    '11069869',
    '11069870',
    '11069871'
  ],
  8255: [
    '16581266',
    '16581267',
    '16581268',
    '16581269',
    '16581270',
    '16581271',
    '16581272',
    '16581273',
    '16581274',
    '16581275',
    '16581276',
    '16581277',
    '16581278',
    '16581279',
    '16581280',
    '16581281',
    '16581282',
    '16581283',
    '16581284',
    '16581285',
    '16581286',
    '16581287',
    '16581288',
    '16581289'
  ],
  446: [
    '1988',
    '1989',
    '1990',
    '1991',
    '1992',
    '1993',
    '1994',
    '1995',
    '1996',
    '1997',
    '1998',
    '1999',
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027'
  ],
  789: [
    '10855',
    '10856',
    '10857',
    '10858',
    '10859',
    '10860',
    '10861',
    '10862',
    '10863',
    '10864',
    '10865',
    '10866',
    '10867',
    '10868',
    '10869',
    '10870',
    '10871',
    '10872',
    '10873',
    '10874',
    '10875',
    '10876',
    '10877',
    '10878',
    '10879',
    '10880',
    '10881',
    '10882',
    '10883',
    '10884',
    '10885',
    '10886',
    '10887',
    '10888',
    '10889',
    '10890',
    '10891',
    '10892',
    '10893',
    '10894'
  ],
  11537: [
    '52002734',
    '52002735',
    '52002736',
    '52002737',
    '52002738',
    '52002739',
    '52002740',
    '52002741',
    '52002742',
    '52002743',
    '52002744',
    '52002745',
    '52002746',
    '52002747',
    '52002748',
    '52002749',
    '52002750',
    '52002751',
    '52002752',
    '52002753',
    '52002754',
    '52002755',
    '52002756',
    '52002757',
    '52002758',
    '52002759',
    '52002760',
    '52002761',
    '52002762',
    '52002763',
    '52002764',
    '52002765',
    '52002766',
    '52002767',
    '52002768',
    '52002769',
    '52002770',
    '52002771',
    '52002772',
    '52002773'
  ],
  11538: [
    '51626494',
    '51626495',
    '51626496',
    '51626497',
    '51626498',
    '51626499',
    '51626500',
    '51626501',
    '51626502',
    '51626503',
    '51626504',
    '51626505',
    '51626506',
    '51626507',
    '51626508',
    '51626509',
    '51626510',
    '51626511',
    '51626512',
    '51626513',
    '51626514',
    '51626515',
    '51626516',
    '51626517',
    '51626518',
    '51626519',
    '51626520',
    '51626521',
    '51626522',
    '51626523',
    '51626524',
    '51626525',
    '51626526',
    '51626527',
    '51626528',
    '51626529',
    '51626530',
    '51626531',
    '51626532',
    '51626533'
  ],
  11539: [
    '52114110',
    '52114111',
    '52114112',
    '52114113',
    '52114114',
    '52114115',
    '52114116',
    '52114117',
    '52114118',
    '52114119',
    '52114120',
    '52114121',
    '52114122',
    '52114123',
    '52114124',
    '52114125',
    '52114126',
    '52114127',
    '52114128',
    '52114129',
    '52114130',
    '52114131',
    '52114132',
    '52114133',
    '52114134',
    '52114135',
    '52114136',
    '52114137',
    '52114138',
    '52114139',
    '52114140',
    '52114141',
    '52114142',
    '52114143',
    '52114144',
    '52114145',
    '52114146',
    '52114147',
    '52114148',
    '52114149'
  ]
}
