import React, { useState } from 'react'
import { Switch, Route, Link, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import AIYA_LOGO from '../assets/images/AIYA_LOGO.png'
import ImageRenderer from '../components/ImageRenderer'
import Chat from '../pages/Chat'
import Inbox from '../pages/Inbox'
import { gql, useQuery } from '@apollo/react-hooks'

const INBOX_SIZE = 450

const GET_BOT_ROLE = gql`
  query getBotRole($id: String!) {
    getBotRole(id: $id)
  }
`

const AppContainer = ({ match }) => {
  const { botId } = useParams()
  const initPath = match.path
  const isDesktop = useMediaQuery({ minDeviceWidth: 1224 })
  const isMobileOrTablet = useMediaQuery({ maxDeviceWidth: 1224 })
  const [role, setRole] = useState('admin')
  useQuery(GET_BOT_ROLE, {
    fetchPolicy: 'no-cache',
    variables: { id: botId },
    onError: err => console.error(err),
    onCompleted: ({ getBotRole }) => {
      setRole(getBotRole)
    }
  })
  return (
    <>
      {isMobileOrTablet && (
        <Switch>
          <Route path={initPath + '/list'} component={Inbox} />
          <Route path={initPath + '/chat/:inboxId'} component={Chat} />
        </Switch>
      )}
      {isDesktop && (
        <>
          {/* <Link to='/'>
            <div
              style={{
                height: 60,
                width: '100%',
                padding: '6px 24px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
              }}
            >
              <ImageRenderer src={AIYA_LOGO} height={48} alt="logo" />
            </div>
          </Link> */}
          <div
            onClick={() => {
              const host = window.location.host
              const [subdomain] = host.split('.')
              const [env] = subdomain.split('-')
              let destHost = 'cloud.aiya.ai'

              if (env === 'dev') {
                destHost = 'dev-cloud.aiya.ai'
              } else if (env === 'uat') {
                destHost = 'uat-cloud.aiya.ai'
              } else if (host === 'localhost:3001') {
                destHost = 'localhost:3002'
              }
              if (role === 'agent') {
                window.location.href = `https://${destHost}`
              } else {
                window.location.href = `https://${destHost}/account/@${botId}/home/dashboard`
              }
            }}
          >
            <ImageRenderer src={AIYA_LOGO} alt="logo" height={48} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', height: 'calc(100% - 60px)' }}>
            <div
              style={{
                width: INBOX_SIZE,
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                position: 'relative'
              }}
            >
              <Route path={initPath} component={Inbox} />
            </div>
            <div style={{ width: `calc(100% - ${INBOX_SIZE}px)`, position: 'relative' }}>
              <Route path={initPath + '/chat/:inboxId'} component={Chat} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AppContainer
