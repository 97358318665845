import React from 'react'
import PT from 'prop-types'
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Switch, Select, Button, Modal, Tag, Popover, Divider } from 'antd'
import { useMediaQuery } from 'react-responsive'

import color from '../../constants/color'
import ImageRenderer from '../../components/ImageRenderer'
import InputField from '../../components/InputField'

const IMAGE_SIZE = 48

const ChatHeader = ({
  data,
  onBack,
  onToggleBot,
  loading,
  onUpdateProfile,
  botTags,
  learning,
  onToggleLearning,
  handleSelectedTab,
  botEnabled
}) => {
  const isMobileOrTablet = useMediaQuery({ maxDeviceWidth: 1224 })

  const platformColor = ["LineBot", "LineModular"].includes(data.platform) ? color.LINE_THEME : color.FB_THEME

  const [showModal, setShowModal] = React.useState(false)
  const [showPopover, setShowPopover] = React.useState(false)
  const [profileInput, setProfileInput] = React.useState({
    tags: data.tags,
    status: data.status
  })

  React.useEffect(() => {
    setProfileInput({
      tags: data.tags,
      status: data.status
    })
  }, [data])

  const profileChange = item => {
    setProfileInput({ ...profileInput, ...item })
  }

  const handleUpdateProfile = () => {
    onUpdateProfile(profileInput)
    setShowModal(false)
  }

  const handleSwitchChange = val => {
    onToggleBot(val)
  }
  const statusObj = {
    DEFAULT: 'ทั่วไป',
    FOLLOWED_UP: 'ต้องดำเนินการ',
    DONE: 'ดำเนินการแล้ว',
    SPAM: 'สแปม'
  }

  const clickMenu = (value) => {
    handleSelectedTab(value)
    setShowPopover(false)
  }

  const menuMobile = (
    <div>
      <div style={{ marginTop: '4px' }} onClick={() => clickMenu('tabUserInfo')}>ข้อมูลส่วนตัว</div>
      <Divider style={{ margin: '16px 0' }} />
      <div style={{ marginBottom: '4px' }} onClick={() => clickMenu('tabComment')}>บันทึกเพิ่มเติม</div>
      <Divider style={{ margin: '16px 0' }} />
      <div style={{ marginBottom: '4px' }} onClick={() => clickMenu('tabOther')}>อื่นๆ</div>
    </div>
  )

  return (
    <div
      style={{
        height: 60,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
        {isMobileOrTablet && (
          <ArrowLeftOutlined
            onClick={onBack}
            style={{ marginRight: 12, fontSize: '1.2em', cursor: 'pointer' }}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {!isMobileOrTablet && <ImageRenderer
            src={data.picture_url}
            alt={data.title || 'profile'}
            style={{
              marginRight: 16,
              borderRadius: '50%',
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              backgroundColor: '#cccccc',
              border: `3px solid ${platformColor}`
            }}
          />}
          <div>
            <div style={{ fontWeight: 600, fontSize: '1.4em' }}>{data.title}</div>
            <div style={{ fontWeight: 500, fontSize: '0.8em' }}>
              {/* {!isMobileOrTablet &&
                data.tags &&
                data.tags.map(tag => {
                  return <Tag key={tag}>{tag}</Tag>
                })} */}
              <Modal
                title="Update "
                visible={showModal}
                onOk={handleUpdateProfile}
                onCancel={() => setShowModal(false)}
              >
                <InputField title="แท็ก">
                  <Select
                    mode="tags"
                    size="small"
                    style={{ width: '100%' }}
                    value={profileInput.tags}
                    onChange={val => profileChange({ tags: val })}
                    disabled={loading}
                  >
                    {(botTags || []).map(t => (
                      <Select.Option key={t.title}>{t.title}</Select.Option>
                    ))}
                  </Select>
                </InputField>
                <InputField title="สถานะ">
                  <Select
                    style={{ width: '100%' }}
                    value={profileInput.status}
                    onChange={val => profileChange({ status: val })}
                  >
                    <Select.Option key="DEFAULT">ทั่วไป</Select.Option>
                    <Select.Option key="FOLLOWED_UP">ต้องดำเนินการ</Select.Option>
                    <Select.Option key="DONE">ดำเนินการแล้ว</Select.Option>
                    <Select.Option key="SPAM">สแปม</Select.Option>
                  </Select>
                </InputField>
              </Modal>
            </div>
          </div>
          {!isMobileOrTablet && (
            <div style={{ marginLeft: '20px' }}>
              {statusObj[profileInput.status]}
              <Button
                onClick={() => setShowModal(true)}
                style={{ marginLeft: '10px' }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isMobileOrTablet && <span style={{ marginRight: 8 }}>การใช้งานบอท</span>}
        <Switch loading={loading} checked={botEnabled} onChange={handleSwitchChange} />
        {
          isMobileOrTablet && (<div>
            <Popover
              content={menuMobile}
              trigger='click'
              onVisibleChange={(value) => {
                setShowPopover(value)
              }}
              visible={showPopover}
            >
              <ImageRenderer
                src={data.picture_url}
                alt={data.title || 'profile'}
                style={{
                  marginLeft: 16,
                  borderRadius: '50%',
                  width: IMAGE_SIZE,
                  height: IMAGE_SIZE,
                  backgroundColor: '#cccccc',
                  border: `3px solid ${platformColor}`
                }}
              />
            </Popover>
          </div>)
        }
        {/* {isMobileOrTablet && (
          <Button
            onClick={() => setShowModal(true)}
            style={{ marginLeft: '10px' }}
            shape="circle"
            icon={<SettingOutlined />}
          />
        )} */}
      </div>
    </div>
  )
}

ChatHeader.propTypes = {
  data: PT.shape({
    id: PT.string,
    title: PT.string,
    picture_url: PT.string,
    status: PT.string,
    tags: PT.arrayOf(PT.string),
    platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineModular']),
    bot_disabled: PT.bool
  }),
  loading: PT.bool,
  onBack: PT.func,
  onToggleBot: PT.func,
  onUpdateProfile: PT.func,
  botTags: PT.array,
  learning: PT.bool,
  onToggleLearning: PT.func,
  handleSelectedTab: PT.func
}

ChatHeader.defaultProps = {
  onBack: () => {},
  onToggleBot: () => {}
}

export default ChatHeader
