import React from 'react'
import Lightbox from 'react-image-lightbox'
import PT from 'prop-types'
import { FileImageOutlined } from '@ant-design/icons'

import ImageRenderer from '../../../components/ImageRenderer'

const IMAGE_SIZE = 220

const ImageMessage = ({ imageUrl, onLoaded }) => {
  const [lightboxState, setLightboxState] = React.useState(false)

  return (
    <>
      <ImageRenderer
        src={imageUrl}
        errorComponent={
          <div
            style={{
              textAlign: 'center',
              padding: 12,
              backgroundColor: '#eaeaea',
              color: '#7e7e7e',
              borderRadius: 8,
            }}
          >
            <FileImageOutlined style={{ fontSize: '2.2em' }} />
            <div>
              <small>Image has been expired.</small>
            </div>
          </div>
        }
        alt="image message"
        onLoad={onLoaded}
        onClick={() => setLightboxState(true)}
        style={{
          width: IMAGE_SIZE,
          borderRadius: 8,
          cursor: 'pointer',
        }}
      />
      {lightboxState && (
        <Lightbox
          mainSrc={imageUrl}
          onCloseRequest={() => setLightboxState(false)}
          enableZoom={false}
        />
      )}
    </>
  )
}

ImageMessage.propTypes = {
  imageUrl: PT.string,
  onLoaded: PT.func,
}

ImageMessage.defaultProps = {
  onLoaded: () => {},
}

export default ImageMessage
